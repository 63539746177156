import { format } from "date-fns";
import Link from "next/link";
import imageUrlFor from "../../../utils/imageUrlFor";
import styles from "./MediumCard.module.scss";

type Article = {
    primaryImage: string;
    title: string;
    categoryTitle: string;
    created: string;
    summary: string;
    slug: string;
};

type MediumCardProps = {
    article: Article;
    showSummary?: boolean;
};

const MediumCard = ({
    article,
    showSummary = true,
}: MediumCardProps): JSX.Element => {
    const { primaryImage, title, categoryTitle, created, summary, slug } = article;
    
    return (
        <div className={styles.wrapper}>
            <Link href={`/articles/${slug}`}>
                <a>
                    <div>
                        <div className={styles.imageWrapper}>
                            <img
                                src={imageUrlFor(primaryImage)
                                    .width(300)
                                    .auto('format')
                                    .url()}
                                className={styles.image}
                                alt=""
                            />
                        </div>

                        <h2 className={styles.title}>{title}</h2>
                    </div>
                </a>
            </Link>
            <div className={styles.articleInfo}>
                <span>{categoryTitle}</span>
                <span> / {format(new Date(created), "dd/MM/yyyy HH:mm")}</span>
            </div>
            {/* {showSummary && <p className={styles.summary}>{summary}</p>} */}
        </div>
    );
};

export default MediumCard;
