import { format } from "date-fns";
import Link from "next/link";
import { TiCalendarOutline } from "react-icons/ti";

import imageUrlFor from "../../../utils/imageUrlFor";

import styles from "./OverlayCard.module.scss";


type Article = {
    author: string;
    title: string;
    categoryTitle: string;
    categoryForeground: string;
    categoryBackground: string;
    primaryImage: any;
    created: string;
    slug: string;
    tags: string[];
    darkTextOnOverlay?: boolean;
};

type OverlayCardProps = {
    article: Article;
    size?: string;
};

const OverlayCard = ({
    article,
    size = "large",
}: OverlayCardProps): JSX.Element => {
    const {
        title,
        primaryImage,
        categoryTitle,
        categoryForeground,
        categoryBackground,
        created,
        slug,
        darkTextOnOverlay,
    } = article;

    const foreground = categoryForeground ? categoryForeground : "FFFFFF";
    const background = categoryBackground ? categoryBackground : "006400";

    return (
        <Link href={`/articles/${slug}`}>
            <a>
                <div
                    className={styles.wrapper}
                    style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.9)), url(${imageUrlFor(
                            primaryImage
                        )
                            .width(600)
                            .url()})`,
                    }}
                >
                    <div className={styles.overlay}>
                        <span
                            style={{
                                backgroundColor: `#${background}`,
                                color: `#${foreground}`,
                            }}
                            className={styles.category}
                        >
                            {categoryTitle}
                        </span>
                        <h2
                            className={[
                                styles.title,
                                darkTextOnOverlay ? styles.darkTitle : null,
                                size === "small" ? styles.small : null,
                                size === "medium" ? styles.medium : null,
                            ].join(" ")}
                        >
                            {title}
                        </h2>
                    </div>
                </div>
            </a>
        </Link>
    );
};

export default OverlayCard;
