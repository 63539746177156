import Link from "next/link";
import format from "date-fns/format";
import imageUrlFor from "../../../utils/imageUrlFor";
import styles from "./MicroCard.module.scss";

type Article = {
    primaryImage: string;
    title: string;
    categoryTitle: string;
    created: string;
    summary: string;
    slug: string;
};

type MicroCardProps = {
    article: Article;
    size: string;
};

const MicroCard = ({ article, size }: MicroCardProps): JSX.Element => {
    const { primaryImage, title, categoryTitle, created, summary, slug } =
        article;
    return (
        <div className={styles.wrapper}>
            <img
                src={imageUrlFor(primaryImage).width(250).url()}
                className={[
                    styles.image,
                    size === "medium" ? styles.medium : null,
                ].join(" ")}
                alt=""
            />
            <div className={styles.content}>
                <Link href={`/articles/${slug}`}>
                    <a>
                        <h2 className={styles.title}>{title}</h2>
                    </a>
                </Link>
                <div className={styles.articleInfo}>
                    <span>{categoryTitle}</span>
                    <span>
                        {" "}
                        / {format(new Date(created), "dd/MM/yyyy HH:mm")}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MicroCard;
