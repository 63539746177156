import { ReactChild } from "react";
import styles from "./HeadlineArticles.module.scss";

type HeadlineArticlesProps = {
    children?: ReactChild[];
};

const HeadlineArticles = ({ children }: HeadlineArticlesProps): JSX.Element | null => {
    if(!children) return null;
    return (
        <div className={styles.wrapper}>
            <div style={{ gridArea: "main" }}>{ children[0] }</div>
            <div style={{ gridArea: "second" }}>{ children[1] }</div>
            <div style={{ gridArea: "third" }}>{ children[2] }</div>
            <div style={{ gridArea: "fourth" }}>{ children[3] }</div>
        </div>
    );
};

export default HeadlineArticles;
