import styles from "./ActivityIndicator.module.scss";

type ActivityIndicatorProps = {};

const ActivityIndicator: React.FC<ActivityIndicatorProps> = () => {
    return (
        <div className={styles.ldsRing}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default ActivityIndicator;
