import { useEffect, useState } from "react";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import ActivityIndicator from "../ActivityIndication/ActivityIndicator";

import styles from "./Subscribe.module.scss";

type SubscribeProps = {};

let emailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

const Subscribe: React.FC<SubscribeProps> = () => {
    const [state, setState] = useState("ready");
    const [buttonText, setButtonText] = useState("Zapisz mnie");
    const [email, setEmail] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);

    useEffect(() => {
        setIsValidEmail(emailRegex.test(email));
    }, [email]);

    const handleSubscribe = async (e: any) => {
        e.preventDefault();
        setState("subscribing");
        setButtonText("Trwa zapisywanie");
        const result = await fetch("/api/subscribe", {
            method: "POST",
            body: JSON.stringify({
                email,
            }),
        });
        if (result.ok) {
            setState("subscribed");
            setButtonText("Zapisano");
        } else {
            setState("error");
            setButtonText("Błąd");
        }
        setTimeout(() => {
            setState("ready");
            setButtonText("Zapisz mnie");
            setEmail("");
        }, 3000);
    };

    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>Zapisz mnie</h3>
            <p className={styles.body}>
                Zapisz się na mój newsletter, żeby raz w tygodniu otrzymywać
                wiadomości w pigułce prosto na email.
            </p>
            <div className={styles.form}>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="adres email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button
                    onClick={(e) => handleSubscribe(e)}
                    className={[styles.button, styles[state]].join(" ")}
                    disabled={!isValidEmail || state !== "ready"}
                >
                    {state === "subscribing" ? (
                        <ActivityIndicator />
                    ) : (
                        buttonText
                    )}
                </button>
            </div>
        </div>
    );
};

export default Subscribe;
