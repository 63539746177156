import Link from "next/link";
import { ReactNode } from "react";
import styles from "./Section.module.scss";

type SectionProps = {
    title: string;
    type: string;
    children?: ReactNode;
    titleLink?: string;
};

const Section = ({
    title,
    type,
    children,
    titleLink,
}: SectionProps): JSX.Element => {
    let sectionStyle;
    switch (type) {
        case "primary":
            sectionStyle = styles.primary;
            break;
        case "three-column":
            sectionStyle = styles.threeColumn;
            break;
        case "four-column":
            sectionStyle = styles.fourColumn;
            break;
        default:
            sectionStyle = styles.twoColumn;
    }

    return (
        <section className={styles.wrapper}>
            {titleLink ? (
                <Link href={titleLink}>
                    <div className={[styles.heading, styles.clickable].join(' ')}>
                        <h3 className={styles.title}>
                            <a href={titleLink}>{title}</a>
                        </h3>
                    </div>
                </Link>
            ) : (
                <div className={styles.heading}>
                    <h3 className={styles.title}>{title}</h3>
                </div>
            )}
            <div className={sectionStyle}>{children}</div>
        </section>
    );
};

export default Section;
