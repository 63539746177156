import sanityClient from "@sanity/client";

const sanityConfig = {
  projectId: "wsbmpik3",
  dataset: "production",
  apiVersion: '2021-12-13',
  useCdn: false
};

export default sanityClient(sanityConfig);

export const sanityClientWithCDN = sanityClient({ ...sanityConfig, useCdn: true});