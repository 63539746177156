import styles from './Disclaimer.module.scss'

const Disclaimer = () => {
    return (
        <div className={styles.wrapper} >
            <p>Wiadomości podawane są w dobrej wierze i są poprawne według naszej najlepszej wiedzy, w momencie publikacji. Nie bierzemy jednak odpowiedzialności za decyzje podejmowane na ich podstawie. Wiadomości nie stanowią porad prawnych ani medycznych. Zawsze sprawdzaj aktualne na oficjalnych stronach.</p>
        </div>
    )
}

export default Disclaimer;