import { ReactNode } from 'react';
import styles from './MicroCardGroup.module.scss'

type MicroCardGroupProps = {
    children?: ReactNode,
    invert: boolean | undefined
}

const MicroCardGroup = ({ children, invert = false }: MicroCardGroupProps): JSX.Element => {
    return (
        <div className={[styles.wrapper, invert ? styles.invert : null].join(' ')} >
            { children }
        </div>
    )
}

export default MicroCardGroup;