import type { NextPage } from "next";
import Head from "next/head";
import MediumCard from "../components/Cards/MediumCard/MediumCard";
import MicroCard from "../components/Cards/MicroCard/MicroCard";
import OverlayCard from "../components/Cards/OverlayCard/OverlayCard";
import HeadlineArticles from "../components/HeadlineArticles/HeadlineArticles";
import Layout from "../components/Layout/Layout";
import MicroCardGroup from "../components/MicroCardGroup/MicroCardGroup";
import Section from "../components/Section/Section";
import styles from "../styles/Home.module.css";
import GoogleAd from "../components/Ads/GoogleAd";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import { useEffect } from "react";
import {
    getAllArticles,
    getArticlesByCategory,
    getHomePageSettings,
    getLatestInfoArticles,
} from "../lib/sanity/articleQueries";
import { loadAds } from "../lib/ga";
import Subscribe from "../components/Subscribe/Subscribe";

const generateArticleList = (articles: any) => {
    const cards = articles.map((article: any, index: number) => {
        return (
            <MediumCard
                key={`article-${index}`}
                article={article}
                showSummary={false}
            />
        );
    });

    cards.splice(
        2,
        0,
        <div key={`ad-1`} style={{ maxHeight: "250px" }}>
            <GoogleAd slot="8024043294" />
        </div>
    );

    return cards;
};

const Home = ({ headlineArticles, title, articles, ukArticles, travelArticles, infoArticles }: Props) => {
    useEffect(() => {
        // @ts-ignore
        if (typeof window.adsbygoogle === "undefined") {
            const interval = setInterval(() => {
                // @ts-ignore 
                if (typeof window.adsbygoogle !== "undefined") {
                    loadAds();
                    clearInterval(interval);
                }
            }, 100);
        } else {
            loadAds();
        }
    }, []);

    return (
        <div className={styles.container}>
            <Head>
                <title>
                    Wiadomości dla Polaków w Wielkiej Brytanii
                </title>
                <meta
                    name="description"
                    content="Najświeższe i rzetelne wiadomości z oficjalnych źródeł dotyczące zmian w wymogach krajówych oraz podróży pomiędzy Polską, a Wielką Brytanią przygotowane przez Aleksandrę Fiddler"
                />
                <link rel="canonical" href="https://wiadomosci.co.uk" />
            </Head>
            <Layout title={title}>
                <HeadlineArticles>
                    <OverlayCard article={headlineArticles.primary} />
                    <OverlayCard
                        article={headlineArticles.secondary}
                        size="medium"
                    />
                    <OverlayCard
                        article={headlineArticles.minor1}
                        size="small"
                    />
                    <OverlayCard
                        article={headlineArticles.minor2}
                        size="small"
                    />
                </HeadlineArticles>
                <GoogleAd slot="2671612442" responsive={true} />
                <Section title="UK" type="primary" titleLink="/categories/UK">
                    <MediumCard article={ukArticles[0]} showSummary={false}/>
                    <MediumCard article={ukArticles[1]} showSummary={false}/>
                    <MicroCardGroup invert={true}>
                        <MicroCard article={ukArticles[2]} size="small" />
                        <MicroCard article={ukArticles[3]} size="small" />
                        <MicroCard article={ukArticles[4]} size="small" />
                    </MicroCardGroup>
                </Section>
                <Section title="Podróże" type="four-column" titleLink="/categories/Podróże">
                    <MediumCard article={travelArticles[0]}/>        
                    <MediumCard article={travelArticles[1]}/>        
                    <MediumCard article={travelArticles[2]}/>        
                    <MediumCard article={travelArticles[3]}/>        
                </Section>
                <Section title="PRZECZYTAJ TAKŻE" type="four-column">

                    {generateArticleList(articles)}
                </Section>
                <div
                    className="adplugg-tag"
                    data-adplugg-zone="above_footer"
                ></div>
                <Subscribe />
                <Disclaimer />
            </Layout>
        </div>
    );
};

export const getServerSideProps = async () => {
    const pageSettings = await getHomePageSettings();

    const usedArticles = [
        `"${pageSettings.primary._id}"`,
        `"${pageSettings.secondary._id}"`,
        `"${pageSettings.minor1._id}"`,
        `"${pageSettings.minor2._id}"`,
    ];

    const travelArticles = await getArticlesByCategory('Podróże', 4, usedArticles);
    const ukArticles = await getArticlesByCategory('UK', 5, usedArticles);
    const infoArticles = await getLatestInfoArticles(4);


    travelArticles.forEach((article: any) => usedArticles.push(`"${article._id}"`))
    ukArticles.forEach((article: any) => usedArticles.push(`"${article._id}"`))
    
    const articles = await getAllArticles(usedArticles);
    
    return {
        props: {
            headlineArticles: {
                primary: pageSettings.primary,
                secondary: pageSettings.secondary,
                minor1: pageSettings.minor1,
                minor2: pageSettings.minor2,
            },
            articles,
            ukArticles,
            travelArticles,
            infoArticles: infoArticles.articles,
            title: pageSettings.title,
        },
    };
};

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;
type Props = UnwrapPromise<ReturnType<typeof getServerSideProps>>["props"];

export default Home;
